import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useMemo } from "react";
import { getEventWiseVideoMatrixCountApi } from "../../apis/dashboard.api";
import moment from "moment";
import fileDownload from "js-file-download";

const ListController = () => {
  const dispatch = useDispatch();

  const title = "SimsSoGram";
  const fetchApi = getEventWiseVideoMatrixCountApi;
  const [list, setList] = useState({});

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "event",
        label: "Event Name",
        align: "left",
        // maxWidth: "200px",
        sort: true,
        renderValue: (params, setParams) =>
          (params?.event) ?? "Total",
      
      },
      {
        id: 2,
        fieldName: "event_date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params?.event_date).format("DD/MM/YYYY"),
      },
      {
        id: 3,
        fieldName: "event_beneficiary_count",
        label: "Device Donated",
        align: "left",
        // renderValue: (params, setParams) =>
        //   (params?.event_beneficiary_count.beneficiaries)
      },
      // {
      //   id: 4,
      //   fieldName: "beneficiary_count",
      //   label: "Video Uploaded Beneficiary",
      //   align: "left",
      // },
      {
        id: 5,
        fieldName:"total_videos_count",
        label: "Total Videos",
        align: "left",
        //  renderValue: (params, setParams) =>
        //   // console.log("PARAMS",params))
        //   (params?.total_videos_count ? params?.total_videos_count : params?.all_total_videos_count
        //   )
      },
      { id: 6, fieldName: "final_videos", label: "Upload", align: "left" },
      {
        id: 7,
        fieldName: "manager_approve_videos",
        label: "Checked",
        align: "left",
      },
      { id: 8, fieldName: "inreview_videos", label: "InReview", align: "left" },
      { id: 9, fieldName: "rejected_videos", label: "Rejected", align: "left" },
      // { id: 2, fieldName: 'allowed_role', label: 'User Type', align: "left", renderValue: (params, setParams) => findObjectKeyByValue(params.allowed_role, USER_ROLES) },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["event_id"],
    sort: "event_date",
    sortDirection: -1,
  });

  const [loading, setLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);

  //   const fetchList = () => {
  //     setLoading(true);
  //     dispatch(
  //       callApiAction(
  //         async () => await fetchApi({ ...filters }),
  //         (response) => {
  //           console.log("Response", response);
  //           setList(response);
  //           setLoading(false);
  //         },
  //         (err) => {
  //           setLoading(false);
  //         }
  //       )
  //     );
  //   };

  const fetchList = (isExport) => {
    if (!isExport) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters }),
          (response) => {
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters, csv: true }),
          (response) => {
            fileDownload(response, "tablematrix.csv");
            setexportLoading(false);
          },
          (err) => {
            console.log(err);
            setexportLoading(false);
          },
          true
        )
      );
    }
  };

  const onExportBtnClick = () => {
    fetchList(true);
  };
  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        list={list}
        setList={setList}
        loading={loading}
        columns={columns}
        exportLoading={exportLoading}
        onExportBtnClick={onExportBtnClick}
      />
    </>
  );
};
export default ListController;
