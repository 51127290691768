import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";




export const getDashboardApi = async params => {
  const callResponse = await axios({
    url: endpoints.dashboardUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getHearingStateCountApi = async params => {
  const callResponse = await axios({
    url: endpoints.hearingstateUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getSpeechStateCountApi = async params => {
  const callResponse = await axios({
    url: endpoints.speechstateUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getAvgListApi = async params => {
  const callResponse = await axios({
    url: endpoints.averageListUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getEventWiseVideoMatrixCountApi = async params => {
  const callResponse = await axios({
    url: endpoints.eventWiseVideoMatrixCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};