import { Box, styled } from "@mui/system"
import SubmitButton from "../../components/button/SubmitButton"
import DataTable from "../../components/tables/DataTable"
import { ListItem, Paper, Typography } from "@mui/material"
import { getEventApi } from "../../apis/event.api"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import { StyledSearchBar } from "../../components/inputs/SearchBar"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list,setList, loading, columns,exportLoading,onExportBtnClick }) => {
    console.log("list results",list)
    console.log("columnns",columns)
    // if(columns.id == 5){
    //   "columns.fieldName":all_total_videos_count,
    // }
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                            <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />
                            </Box>
                        
                        </FilterTitleBox>
                        <PaddingBoxInDesktop
                  mb={1}
                  mt={1}
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                //   pl={3}
                >
                  <AsyncDropDown
                    lazyFun={async (para) => {
                      return await getEventApi({ ...para });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        donation_event_id: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Event"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
                    </Box>

                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} noPagination={true}/>
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi