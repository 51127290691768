import { ButtonBase, Grid, Grow, ListItem, Paper, Skeleton, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import { useSelector } from "react-redux"
import DataTable from "../../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { getRegionApi } from "../../../apis/region.api"
import { REGIONS_TYPE } from "../../../utils/constants"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getCampApi } from "../../../apis/camp.api"
import { useEffect } from "react"
import { getEventApi } from "../../../apis/event.api"
import CreatableRegionComponent from "../../../components/custom/CreateableRegionComponent"
import SubmitButton from "../../../components/button/SubmitButton"
import { formatNumberCustomPattern } from "../../../utils/helper"
import { getProductApi } from "../../../apis/product.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))

const CountBoxContainer = styled(Box)(({ theme, active }) => ({
    background: active ? theme.palette.primary.main : theme.palette.light.main,
    border: "1px solid " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    "*": {
        color: active ? theme.palette.light.main + "!important" : ''
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))

const CountBoxComponent = ({ part, count, title, subtitle, active, onClick, loading }) => {
    if (loading) {
        return <Grid item sm={part} xs={6}>
            <Skeleton variant="rounded" width="100%" height="100px" >

            </Skeleton>
        </Grid>
    }

    return <Grid item sm={part} xs={6}>
        <CountBoxContainer active={active} component={ButtonBase} onClick={onClick} sx={{ width: "100%" }}  >
            <Box p={2}>

                <Typography color="primary" variant="h3" align="center">
                    {formatNumberCustomPattern(count)}
                </Typography>
                <Typography variant="h6" fontWeight="bold" align="center">
                    {title}
                </Typography>
                {subtitle && <Typography variant="caption" fontWeight="bold" align="center">
                    {subtitle}
                </Typography>}
            </Box>
        </CountBoxContainer>
    </Grid>
}

const ListOfDonationUi = ({ title, filters, setFilters, list, loading, columns, countsLoading, counts, childCount, setChildCounts,exportLoading,onExportBtnClick,pdfLoading,onPdfBtnClick }) => {

    const user = useSelector(state => state.user)
    useEffect(() => {
        if (counts && counts.length > 0) {
            for (let item of counts) {
                if (filters.nuResponse == item.value) {
                    setChildCounts(item.children)
                }
            }
        } else if (!countsLoading) {
            setChildCounts([])
        }
    }, [counts])
    return (
        <>

            <Box component={Paper} p={3} mb={3}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "column" }}>


                    <FiltersBox mt={3} >
                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                        </PaddingBoxInDesktop>


                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                            <AsyncDropDown


                                lazyFun={getCampApi}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, camp: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Camp"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                            <AsyncDropDown


                                lazyFun={getEventApi}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, donation_event_id: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Event"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={getProductApi}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                    </FiltersBox>
                    <FilterTitleBox>


                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            {/* <AsyncDropDown


                                lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.COUNTRY, ...para }) }}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, country: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Country"} {...params} margin="none" />}
                            /> */}

                            <CreatableRegionComponent
                                onlyDropDown
                                disabled={loading}
                                value={typeof filters.country == "object" ? filters.country : null}
                                label={"Select Country*"}
                                type={REGIONS_TYPE.COUNTRY}
                                parent={null}
                                onChange={async (changedVal) => {

                                    setFilters({ ...filters, country: (changedVal ? changedVal._id : null), state: null, district: null, taluka: null })
                                }}
                            />
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            {/* <AsyncDropDown


                                lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.STATE, ...para }) }}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, state: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select State"} {...params} margin="none" />}
                            /> */}
                            <CreatableRegionComponent
                            onlyDropDown
                                disabled={loading || !filters.country || filters.country == null}
                                value={typeof (filters.state) == "object" ? filters.state : null}

                                type={REGIONS_TYPE.STATE}

                                onChange={async (changedVal) => {

                                    setFilters({ ...filters, state: changedVal ? changedVal._id : null, district: null, taluka: null })
                                }}
                                parent={filters.country && typeof (filters.country) == "object" ? filters.country['_id'] : filters.country}
                                label={"Select State*"}

                            />
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            {/* <AsyncDropDown


                                lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.DISTRICT, ...para }) }}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, district: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select District"} {...params} margin="none" />}
                            /> */}
                            <CreatableRegionComponent
                            onlyDropDown
                                disabled={loading || !filters.state || filters.state == null}
                                value={typeof filters.district == "object" ? filters.district : null}
                                type={REGIONS_TYPE.DISTRICT}

                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, district: changedVal ? changedVal._id : null, taluka: null })
                                }}
                                label={"Select District*"}
                                parent={filters.state && typeof (filters.state) == "object" ? filters.state['_id'] : filters.state}
                            />
                        </PaddingBoxInDesktop>


                        <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            {/* <AsyncDropDown


                                lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.TALUKA, ...para }) }}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, taluka: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Taluka"} {...params} margin="none" />}
                            /> */}

                            <CreatableRegionComponent
                            onlyDropDown
                                disabled={loading || !filters.district || filters.district == null}
                                value={typeof filters.taluka == "object" ? filters.taluka : null}
                                type={REGIONS_TYPE.TALUKA}


                                onChange={async (changedVal) => {

                                    setFilters({ ...filters, taluka: changedVal ? changedVal._id : null })
                                }}
                                label={"Select Taluka*"}
                                parent={filters.district && typeof (filters.district) == "object" ? filters.district['_id'] : filters.district}
                            />
                        </PaddingBoxInDesktop>

                    </FilterTitleBox>
                </Box>
                <Grid container spacing={2} mt={2}>
                    {
                        !countsLoading && counts.length > 0 ? <>
                            {
                                counts.map((count) => <CountBoxComponent
                                    key={count.title}
                                    onClick={() => {
                                        setFilters({ ...filters, nuResponse: count.value, donated: null });
                                        setChildCounts(count.children)
                                    }}
                                    setFilters={setFilters}
                                    filters={filters}
                                    active={count.value === filters.nuResponse}
                                    part={12 / counts.length}
                                    title={count.title}
                                    subtitle={count.subtitle}
                                    count={count.count} />)
                            }
                        </> : <>
                            {[0, 1, 2].map(item => <CountBoxComponent part={12 / 3} loading={true} />)}
                        </>
                    }
                </Grid>
            </Box>

            {<Grow in={childCount.length > 0} >
                <Box component={Paper} p={childCount.length <= 0 ? 0 : 3} mb={childCount.length <= 0 ? 0 : 3}>
                    <Grid container spacing={2}>

                        {
                            !countsLoading && childCount.length > 0 ? <>
                                {
                                    childCount.map((count) => <CountBoxComponent onClick={() => { setFilters({ ...filters, donated: count.value, }) }} setFilters={setFilters} filters={filters} active={count.value === filters.donated} part={12 / counts.length} key={count.title} title={count.title} count={count.count} />)
                                }
                            </> : <></>
                        }
                    </Grid>
                </Box></Grow >}

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
{/* 
                        <FiltersBox  > */}
                            <PaddingBoxInDesktop sx={{
                                display: "flex", flex: 1, "*": {
                                    justifyContent: "center"
                                }
                            }}>
                                <Typography variant="h5" mb={4} >{title}</Typography>
                            </PaddingBoxInDesktop>

                            <FiltersBox mb={2}>
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}  >
                            <Box sx={{ display: "flex",flexWrap:"wrap" }}  >
                                {/* <Box mr={1}>
                                <SheetDonwnloadButton />
                                </Box> */}
                                <Box mr={1}>
                                {<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                                </Box>
                                <Box mr={1}>
                                {<SubmitButton loading={pdfLoading} variant="contained" onClick={onPdfBtnClick} title={"Download Pdf"} disabled={!filters.district} />}
                                </Box>
                            </Box>
                        </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{
                                display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                    justifyContent: "center"
                                }
                            }} pl={3} >
                                <StyledSearchBar fullWidth title="Search By  Name | Phone No. " size="small" placeholder={"Search By  Name | Phone No."} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop>



                        </FiltersBox>
                    </Box>

                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListOfDonationUi