import { Autocomplete, Box, CircularProgress, Grid, MenuItem, Paper, Step, StepConnector, StepLabel, Stepper, Typography, stepConnectorClasses, styled } from "@mui/material"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import SubmitButton from "../../components/button/SubmitButton"
import DocumentFields from "./DocumentFields"
import PersonalDetailsFields from "./PersonalDetailsFields"
import TestDetailsFields from "./TestDetailsFields"
import AdditionalDetailsFields from "./AdditionalDetailsFields"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));
const CreateUi = ({buttonDisable, setButtonDisable, fields, setFields, loading,setLoading, step, onPreviosButtonClick, modal }) => {
    const { user } = useSelector(state => state)

    console.log("button disbale 1",buttonDisable)
    return <>

        {(!fields.id && !modal) && <Typography variant="h6" >Add Beneficiary</Typography>}
        {/* { <Typography variant="h6" >Step : {step+1}/3</Typography>}
         */}
        <Stepper alternativeLabel connector={<QontoConnector />} activeStep={step} >
            <Step >
                <StepLabel></StepLabel>
            </Step>
            <Step >
                <StepLabel></StepLabel>
            </Step>
            <Step >
                <StepLabel></StepLabel>
            </Step>
            <Step >
                <StepLabel></StepLabel>
            </Step>
        </Stepper>
        {fields.err && fields.err != '' && <Typography mt={1} color="error" fontWeight="bold" mb={1} variant="body1" align="center">
            {fields.err}
        </Typography>}
        <Box sx={{ display: "flex", width: "100%", flex: 1, justifyContent: "flex-start" }} mt={3}>



            {<Grid container spacing={2}>

                <Grid item md={12} xs={12}  >


                    <PersonalDetailsFields show={step == 0} fields={fields} setFields={setFields} loading={loading} />
                    {step == 1 && <DocumentFields show={step == 1} fields={fields} setFields={setFields} loading={loading} setLoading={setLoading} buttonDisable={buttonDisable} setButtonDisable={setButtonDisable}/>}
                    <TestDetailsFields show={step == 2} fields={fields} setFields={setFields} loading={loading} />
                    <AdditionalDetailsFields show={step == 3} fields={fields} setFields={setFields} loading={loading} />

                </Grid>
            </Grid>}


        </Box>


    </>
}

const CreatePage = ({ title, modalKey, fields, setFields, loading, step, onPreviosButtonClick, onNextButtonClick }) => {
    return (
        <Box mb={1} sx={{ display: "flex", flex: 1 }}>
            <Paper elevation={2} sx={{ width: "100%", padding: 4, paddingBottom: 2, display: "flex", flex: 1, flexDirection: "column" }} >

                <Box component="form" autoComplete="off" onSubmit={onNextButtonClick} sx={{ width: '100%', display: "flex", flex: 1, flexDirection: "column" }} mb={3} >

                    <CreateUi
                        step={step}
                        loading={loading}
                        fields={fields}
                        setFields={setFields}
                        onPreviosButtonClick={onPreviosButtonClick}

                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} mt={4}>
                        {<Box>{step != 0 && <SubmitButton type='button' title="Back" onClick={onPreviosButtonClick} />}</Box>}
                        <Box><SubmitButton loading={loading} type='submit' title={step == 3 ? "Submit" : ("Next" + ``)} /></Box>

                    </Box>


                </Box>
            </Paper>
        </Box>)

}

const CreateModal = memo(({buttonDisable,setButtonDisable, title, modalKey, fields, setFields, loading,setLoading, fetchLoading, isUpdate, step, onPreviosButtonClick, onNextButtonClick }) => {

    console.log("Loading insiede create modal",loading)

    return (
        <CustomDialog
            id={modalKey}
            disableDirectClose
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onNextButtonClick}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            onClose={onPreviosButtonClick}
            closeText={step == 0 ? "Close" : "Back"}
            confirmText={`${step == 3 ? (isUpdate ? "Update" : "Create") : "Next"}`}
            disabledSubmit={buttonDisable == false ? false :true}
            // disabled={loading == true ? true : false}
        >
{                console.log("loading inside custome dialog ",loading)
}
            {
                fetchLoading ? <CenteredBox sx={{ minHeight: "200px" }}><CircularProgress /></CenteredBox> :
                    <CreateUi
                        modal
                        onPreviosButtonClick={onPreviosButtonClick}
                        step={step}
                        loading={loading}
                        fields={fields}
                        setFields={setFields}
                        buttonDisable={buttonDisable} setButtonDisable={setButtonDisable}

                    />
            }
        </CustomDialog>
    )
})
export { CreateModal }
export default memo(CreatePage)