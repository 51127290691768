import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreatePage, { CreateModal } from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { GENDER, SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants"
import { addBeneficiaryApi, getBeneficiaryByIdApi, updateBeneficiaryApi } from "../../apis/beneficiary.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import moment from "moment"
import { beneficiaryFormData, validateEmail, validatePhone, validateaadhar } from "../../utils/helper"
import { BENIFICIARY_LOG_STATUS, SEVEARITY_OF_LOSS, TYPE_OF_HEARING_LOSS, WAY_OF_CONFIRMATION } from "../../utils/beneficiary.constant"

const CreateController = ({ callBack = () => { }, id, role = null ,modal}) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = 'beneficiary'
    const title = "Beneficiary "
    const createApi = addBeneficiaryApi
    const updateApi = updateBeneficiaryApi
    const getByIdApi = getBeneficiaryByIdApi

    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)
    const [buttonDisable,setButtonDisable] = useState(false)

    const defaultFields = {
        err: '',
        id,
        surname: "",
        name: "",
        phone: "",
        email: "",
        photo: "",
        udise_no: "",
        udise_file: "",
        aadhar_file:"",
        income_certi:"",
        gender: GENDER.MALE,
        dob: moment().toISOString(),
        country: beneficiaryFormData.get('country'),
        state: beneficiaryFormData.get('state'),
        district: beneficiaryFormData.get('district'),
        taluka: beneficiaryFormData.get('taluka'),
        addres_line: "",
        PIN: "",
        school: beneficiaryFormData.get('school'),
        std: beneficiaryFormData.get('std'),
        aadhar: '',
        father_name: null,
        father_occupation: null,
        mother_name: null,
        mother_occupation: null,
        family_income: null,
        family_contact: "",

        type_of_hearing_loss: TYPE_OF_HEARING_LOSS.CONDUCTIVE,
        severity_of_hearing_loss: SEVEARITY_OF_LOSS.MILD,
        audio_gram_file: null,
        nu_device_responce: true,
        music_responce: true,
        voice_responce: true,
        has_smart_phone: true,
        left_response_in_db: 0,
        right_response_in_db: 0,
        way_of_confirmation: WAY_OF_CONFIRMATION.VERBAL_CONFIRMATION,
        product: beneficiaryFormData.get('product'),
        teacher: beneficiaryFormData.get('teacher'),
        
        camp: beneficiaryFormData.get('camp'),

        before_beneficiary_video:"",
        after_beneficiary_video:""  ,
        
        wehear_executive_name: "",
        ssa_registered: false,
    disablity_id_no: "",
    family_person_information: {
      person_name: "",
      email: "",
      consanguinitu: "",
    },

        problems: {
          please_tell_us_about_your_hearing_concerns: "",
          hearing: null,
          medical_history: {
            diabetes: false,
            blood_pressure: false,
            heart_problem: false,
            kidney_disease: false,
            tuberculosis: false,
            cancer: false,
            other: "",
            taking_medication: false,
          },
          hearing_difficulty: {
            noisy_place: false,
            quiet_place: false,
            restaurant: false,
            television: false,
            telephone: false,
            one_on_one_talking: false,
          },
          hearing_history: {
            ear_pain: null,
            ear_infection: null,
            ear_surgery: null,
            tinnitus: null,
            fluctuation_in_hearing: null,
            head_ache: false,
            dizziness_vomiting: false,
            noise_exposure: false,
            fullness_in_ear: null,
          },
          hearing_aid: {
            had_hearing_aid: false,
            side: null,
            model: "",
          },
          family_member_hearing_loss: {
            has_family_member_with_hearing_loss: false,
            who: "",
            when_lost_hearing: null,
            additional_information: "",
          },
          conclusion: "",
        }
          
        }    

    
    const [fields, setFields] = useState(defaultFields)
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([
        [
            {
                required: true,
                value: fields.camp,
                field: 'Camp ',
            },
            
            {
                required: true,
                value: fields.country,
                field: 'Country',
            },
            {
                required: true,
                value: fields.state,
                field: 'State',
            },
            {
                required: true,
                value: fields.district,
                field: 'District',
            },
            {
                required: true,
                value: fields.taluka,
                field: 'Taluka',
            },
            {
                // required: true,
                value: fields.teacher,
                field: 'Teacher ',
            },
            {
                required: true,
                value: fields.name,
                field: 'First Name',
            },
            {
                required: true,
                value: fields.phone,

                field: 'Phone No. ',
                custom: () => {
                    if (!validatePhone(fields.phone)) {
                        return "Phone No is invalid."
                    }
                    return true
                }
            },
            {

                value: fields.email,
                field: 'Email',
                custom: () => {
                    if (fields.email && fields.email != '' && !validateEmail(fields.email)) {
                        return "Email is invalid."
                    }
                    return true
                }
            },
            {
                required: true,
                value: fields.gender,
                field: 'Gender',
            },
            {
                required: true,
                value: fields.dob,
                field: 'Date of birth',
            },       
            {             
                value: fields.std,
                field: 'Standard',
                custom: () => {
                    if (fields.std && fields.std != '' && (isNaN(fields.std) || fields.std > 14 || fields.std < -2)) {
                        return "Standard/Grade is invalid."
                    }
                    return true
                }
            },

            {
                required: true,
                value: fields.addres_line,
                field: 'Address ',
            },
            {
                
                value: fields.PIN,
                field: 'PIN ',
                custom: () => {
                    if (fields.PIN && fields.PIN != '' && !/^\d{6}$/.test(fields.PIN)) {
                        return "Invalid PIN"
                    }
                    return true
                }
            },
            {                
                value: fields.aadhar,
                field: 'Aadhar ',
                custom: () => {
                    if (fields.aadhar && fields.aadhar != '' && !validateaadhar(fields.aadhar)) {
                        return "Invalid Aadhar"
                    }
                    return true
                }
            },
            {
                required: true,
                value: fields.family_contact,
                field: 'Guardian Contact ',
                custom: () => {
                    if (!validatePhone(fields.family_contact)) {
                        return "Guardian Contact is invalid."
                    }
                    return true
                }
            }
        ],

        [
       
            
           
            // {
            //     required: true,
            //     value: fields.photo,
            //     field: 'Photo Indentity ',
            // }
        ],
         [
            {
                required: true,
                value: fields.product,
                field: 'Product',
            },
            {
            
                value: fields.left_response_in_db,
                field: 'Left ear response ',
                custom: () => {
                    if (isNaN(fields.left_response_in_db) || fields.left_response_in_db < 0 || fields.left_response_in_db > 120) {
                        return "Left ear response should be between 0 to 120 dB"
                    }
                    return true
                }
            },
            {
                
                value: fields.right_response_in_db,
                field: 'Right ear response ',
                custom: () => {
                    if (isNaN(fields.right_response_in_db) || fields.right_response_in_db < 0 || fields.right_response_in_db > 120) {
                        return "Right ear response should be between 0 to 120 dB"
                    }
                    return true
                }
            }
        ]

    ]), [fields])

    useEffect(() => {

        beneficiaryFormData.set('camp', fields.camp)

    }, [fields.camp])
    useEffect(() => {

        beneficiaryFormData.set('product', fields.product)

    }, [fields.product])
    useEffect(() => {

        beneficiaryFormData.set('country', fields.country)

    }, [fields.country])

    useEffect(() => {

        beneficiaryFormData.set('state', fields.state)

    }, [fields.state])
    useEffect(() => {

        beneficiaryFormData.set('district', fields.district)

    }, [fields.district])
    useEffect(() => {

        beneficiaryFormData.set('taluka', fields.taluka)

    }, [fields.taluka])


    useEffect(() => {

        beneficiaryFormData.set('school', fields.school)

    }, [fields.school])
    useEffect(() => {

        beneficiaryFormData.set('std', fields.std)

    }, [fields.std])
    useEffect(() => {

        beneficiaryFormData.set('teacher', fields.teacher)

    }, [fields.teacher])

    useEffect(() => {
        beneficiaryFormData.set('family_person_information', fields.family_person_information);
    }, [fields.family_person_information]);
    
    useEffect(() => {
        beneficiaryFormData.set('problems', fields.problems);
    }, [fields.problems]);
    

    const createFunction = async () => {
        const passedData = {};
        const currentFields = { ...fields };
    
        for (let item in currentFields) {
            if (typeof currentFields[item] === "object") {
                if (item === "family_person_information" || item === "problems") {
                
                    passedData[item] = (currentFields[item]);
                } else {
                
                    passedData[item] = currentFields[item]?._id || currentFields[item];
                }
            } else {
                passedData[item] = currentFields[item];
            }
        }
    
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await createApi(passedData),
                async (response) => {
                    await callBack(response);
                    setLoading(false);
                    setFields({
                        ...defaultFields,
                        camp: fields.camp,
                        country: fields.country,
                        state: fields.state,
                        district: fields.district,
                        taluka: fields.taluka,
                        teacher: fields.teacher,
                        problems: fields.problems,
                    });
                    setStep(0);
                    dispatch(callSnackBar("Data added successfully", SNACK_BAR_VARIETNS.suceess));
                    dispatch(closeModal(modalKey));
                },
                (err) => {
                    setLoading(false);
                    setFields({ ...fields, err });
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.warning));
                }
            )
        );
    };
    

console.log("fields", fields)
const updateFunction = async () => {
    let updatedData = { id };

    for (let field in fields) {
        const fieldVal = fields[field];

        updatedData[field] = typeof fieldVal === "object" && fieldVal !== null
            ? fieldVal._id || fieldVal
            : fieldVal;
    }


        updatedData['status_updated'] = BENIFICIARY_LOG_STATUS.MODIFIED

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await updateApi(updatedData),
                async (response) => {

                    await callBack(response, updatedData)
                    setLoading(false)
                    dispatch(callSnackBar("Data updated successfully", SNACK_BAR_VARIETNS.suceess))
                    dispatch(closeModal(modalKey))
                },
                (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                }
            )
        )

    }



    const onSubmit = async (e) => {

        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setFetchLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    const onNextButtonClick = (e) => {
        e?.preventDefault()
        const validationResponse = validate(validationSchemaForCreate[step])
        if (validationResponse === true) {
            if (step < 3)
                setStep(step + 1)
            else onSubmit(e)

            setFields({ ...fields, err: "" })
        } else {
            setFields({ ...fields, 'err': validationResponse })
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
        }
    }

    const onPreviosButtonClick = () => {
        if (!loading) {
            if ((id||modal) && step == 0) {
                dispatch(closeModal(modalKey))
            }
            setStep(step - 1 >= 0 ? (step - 1) : 0)
        }

    }
    
    if (id || modal) {
        return <CreateModal buttonDisable={buttonDisable} setButtonDisable={setButtonDisable} fetchLoading={fetchLoading} onPreviosButtonClick={onPreviosButtonClick} onNextButtonClick={onNextButtonClick} step={step} modalKey={modalKey} title={title} isUpdate={id} loading={loading} setLoading={setLoading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
    }
    return <CreatePage fetchLoading={fetchLoading} onPreviosButtonClick={onPreviosButtonClick} onNextButtonClick={onNextButtonClick} step={step} modalKey={modalKey} title={title} isUpdate={id} loading={loading} setLoading={setLoading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)